import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { login } from "../../apis/api";
import { useAuth } from "../../configs/AuthContext";
import { Link } from "react-router-dom";
import "./index.css"; // New CSS file for styles
import logo from "../../assets/logo.jpg"; // Assuming logo is in assets folder
import { useNavigate } from "react-router-dom";

interface User {
  id: string;
  email: string;
}

const LoginForm: React.FC = () => {
  const [form] = Form.useForm();
  const { setUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleLogin = async (values: { email: string; password: string }) => {
    const currentTimestamp = Date.now();
    console.log(currentTimestamp, '时间戳')
    // const deadlineTimestamp = 1734019200000;
    if (currentTimestamp > 1737356400000) {
      message.error("抱歉，申报已经截止");
      return;
    }
    setLoading(true);

    try {
      const { email, password } = values;
      const response = await login(email, password);
      if (response.status) {
        sessionStorage.setItem("token", response.data.token);
        setUser(response.data.user);
        message.success("登录成功");
        // 跳转到首页
        navigate("/");
      } else {
        message.error(response.message || "登录失败");
      }
    } catch (error) {
      message.error("登录失败，请检查邮箱或密码");
      console.error("Error logging in: ", error);
    }
    setLoading(false);
  };

  return (
    <div className="login-container">
      {/* <div className="login-time"></div> */}
      {/* <Tag color="red" style={{ fontSize: "16px", padding: "5px 10px" }}>
        申报截止时间:2024年11月30日17:00
      </Tag> */}
      <div className="login-box">
        <img src={logo} alt="Logo" className="login-logo" />
        <h3>上海科普教育发展基金会科普公益项目资助填报系统</h3>

        <Form
          layout="vertical"
          onFinish={handleLogin}
          form={form}
          className="login-form"
        >
          <Form.Item
            label="邮箱"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "请输入有效的电子邮箱",
              },
            ]}
          >
            <Input placeholder="请输入电子邮箱" />
          </Form.Item>
          <Form.Item
            label="密码"
            name="password"
            rules={[{ required: true, message: "请输入密码" }]}
          >
            <Input.Password placeholder="请输入密码" />
          </Form.Item>
          <div className="login-options">
            <Link to="/forgot-password" className="forgot-password-link">
              忘记密码？
            </Link>
          </div>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              登录
            </Button>
          </Form.Item>
          <div className="register-option">
            还没有帐户？<Link to="/register">注册</Link>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default LoginForm;
