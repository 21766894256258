import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { message, Button, Layout, Tag } from "antd";
import { AuthProvider, useAuth } from "./configs/AuthContext";
import "./App.css"; // New CSS file for overall styles
import routesConfig from "./configs/routesConfig"; // 导入集中管理的路由配置
import ProtectedRoute from "./configs/ProtectedRoute"; // 导入受保护的路由组件

const { Footer } = Layout;

const App: React.FC = () => {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
};

const AppContent: React.FC = () => {
  const { user, setUser } = useAuth();

  console.log("===>", user);

  const handleLogout = () => {
    setUser(null);
    sessionStorage.removeItem("token"); // 移除 sessionStorage 中的 token
    message.success("已退出登录");
    //跳转到登录 login
    window.location.href = "#/login"; // 修改为使用片段标识符的形式跳转到登录页面
  };

  return (
    <Router>
      <div className="app-container">
        <nav className="nav-bar">
          {/* <div className="nav-left">
            <Tag className="deadline-tag">
              申报截止时间: 2024年12月16日17:00
            </Tag>
          </div> */}
          <div className="nav-right">
            <Button type="link" href="#/">
              首页
            </Button>
            {user && (
              <Button
                type="link"
                onClick={handleLogout}
                className="logout-button"
              >
                退出
              </Button>
            )}
          </div>
        </nav>
        <div className="content-container">
          <Routes>
            {routesConfig.map(
              ({ path, element, protected: isProtected }, index) => {
                if (isProtected) {
                  return (
                    <Route
                      key={index}
                      path={path}
                      element={<ProtectedRoute>{element}</ProtectedRoute>}
                    />
                  );
                }
                return <Route key={index} path={path} element={element} />;
              }
            )}
          </Routes>
        </div>
        <Footer className="footer">
          主办方：
          <a
            href="https://www.ssedf.org.cn/"
            target="_blank"
            rel="noopener noreferrer"
          >
            上海科普教育发展基金会
          </a>
          <br />
          COPYRIGHT @ ALL RIGHTS RESERVED | 沪ICP备2024066914号 |
          沪公网安备31011502018784号
          <br />
          联系电话：傅老师 64225391-811 |
          联系地址：上海市黄浦区黄河路21号鸿祥大厦5楼
        </Footer>
      </div>
    </Router>
  );
};

export default App;
